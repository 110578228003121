import { Store } from "vuex";
import StoreState from "@/store/StoreState";

import HttpService from "./HttpService";

import CreateFlashcardCollectionRequest from "@/types/API/CreateFlashcardCollectionRequest";
import GetFlashcardCollectionResponse from "@/types/API/GetFlashcardCollectionResponse";
import FlashcardCollection from "@/types/FlashcardCollection";

class FlashcardCollectionService {

    private store: Store<StoreState>;
    private httpService: HttpService;

    constructor(store: Store<StoreState>, httpService: HttpService) {
        this.store = store;
        this.httpService = httpService;
    }

    public async getFlashcardCollectionResponseById(id: string): Promise<GetFlashcardCollectionResponse> {
        try {
            const token = this.store.state.accessToken;

            const response = await this.httpService.get(`collection/${id}`, token);

            return response;
        }
        catch(err: any) {
            throw err as string;
        }
    }

    public async getPublicFlashcardCollections(): Promise<FlashcardCollection[]> {
        try {
            const response = await this.httpService.get(`collection/public`);

            return response;
        }
        catch(err: any) {
            throw err as string;
        }
    }

    public async createFlashcardCollection(createFlashcardCollectionRequest: CreateFlashcardCollectionRequest) {
        try {
            const token = this.store.state.accessToken;

            const response = await this.httpService.post(`collection`, createFlashcardCollectionRequest, token);

            return response;
        }
        catch(err: any) {
            console.log(err);
            throw err as string;
        }
    }

}

export default FlashcardCollectionService;