import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import store from '@/store'

function prefixRoutes(prefix: string, routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
    return routes.map(route => { route.name = route.path = prefix + '/' + route.path; return route; });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  ...prefixRoutes('/collection', [
    { path: '', component: () => import('@/views/collection/PublicCollectionsView.vue') },
    { path: ':id', component: () => import('@/views/collection/FlashcardCollectionView.vue') },
  ]),
  ...prefixRoutes('/user', [
    { path: '', component: () => import('@/views/user/UserView.vue') },
  ]),
  ...prefixRoutes('/auth', [
    { path: 'login', component: () => import('@/views/auth/LoginView.vue') },
    { path: 'forgot-password', component: () => import('@/views/auth/ForgotPasswordView.vue') },
    { path: 'signup', component: () => import('@/views/auth/RegisterView.vue') }
  ])
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const authRequired: boolean = to.path.includes('/user'); // || to.path.includes('/collection');

    if(!store.state.authenticated && authRequired) {
        return next({ 
            path: '/auth/login',
            query: {
                'returnUrl': to.fullPath
            },
            force: true 
        });
    }

    next();
});

export default router
