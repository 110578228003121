
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'NativeModal',
    props: {
        name: {
            required: true,
            type: String
        },
        title: {
            required: true,
            type: String
        }
    },
    emits: [
        'onOpened',
        'onClosed'
    ],
    setup(_, { emit }) {
        const modalOpenedRef = ref<boolean>(false);

        function overlayClicked() {
            closeModal();
        }

        function openModal() {
            modalOpenedRef.value = true;
            emit('onOpened');
        }

        function closeModal() {
            modalOpenedRef.value = false;
            emit('onClosed');
        }

        return { modalOpenedRef, overlayClicked, closeModal, openModal }
    }
})
