
import { defineComponent, inject, ref } from 'vue';
import Container from '@/components/native/Container.vue'; // @ is an alias to /src
import Loader from '@/components/native/Loader.vue'; // @ is an alias to /src
import FormTest from '@/components/FormTest.vue'; // @ is an alias to /src
import Modal from '@/components/native/Modal.vue'; // @ is an alias to /src
import Button from '@/components/native/Button.vue'; // @ is an alias to /src

import FlashcardCollectionService from '@/services/FlashcardCollectionService';

export default defineComponent({
    name: 'HomeView',
    components: {
        Container,
        Loader,
        FormTest,
        Modal,
        Button
    },
    setup() {
        const exampleModal = ref();

        const flashcardCollectionService = inject('flashcardCollectionService') as FlashcardCollectionService;

        async function loadPublicFlashcardCollections() {
            const response = await flashcardCollectionService.getPublicFlashcardCollections();

            console.log(response);
        }

        loadPublicFlashcardCollections();
        return { exampleModal }
    }
});
