
import { defineComponent } from 'vue';
import FormSubmitEvent from '@/types/native/events/FormSubmitEvent';

export default defineComponent({
    name: 'NativeForm',
    emits: [
        'onSubmitted'
    ],
    setup(_, { emit }) {
        function emitSubmitEvent(e: any) {
            const submitEvent: FormSubmitEvent = {
                formData: new FormData(e.srcElement)
            }

            emit('onSubmitted', submitEvent);
        }

        return { emitSubmitEvent };
    }
})
