import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77e0717e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["id", "name", "placeholder", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("input", {
      id: _ctx.htmlId,
      name: _ctx.htmlId,
      placeholder: _ctx.placeholder,
      type: _ctx.type,
      class: "form-control"
    }, null, 8, _hoisted_2)
  ]))
}