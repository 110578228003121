
import { computed, defineComponent, PropType } from 'vue'

import User from '@/types/User';

export default defineComponent({
    props: {
        user: {
            required: true,
            type: Object as PropType<User>
        }
    },
    setup(props) {
        const svgImage = computed(() => `https://avatars.dicebear.com/api/identicon/${props.user.displayName}.svg`);

        return { svgImage }
    }
})
