import { Store } from "vuex";
import StoreState from "@/store/StoreState";

import HttpService from "@/services/HttpService";
import CreateFlashcardRequest from "@/types/API/CreateFlashcardRequest";
import GetFlashcardResponse from "@/types/API/GetFlashcardResponse";

class FlashcardService {

    private store: Store<StoreState>;
    private httpService: HttpService;

    constructor(store: Store<StoreState>, httpService: HttpService) {
        this.store = store;
        this.httpService = httpService;
    }

    public async getFlashcardById(id: number) {
        try {
            const token = this.store.state.accessToken;

            const response = await this.httpService.get(`${id}`, token);

            return response;
        }
        catch(err: any) {
            console.log(err);
            throw err as string;
        }
    }

    public async getFlashcardResponsesByCollectionId(collectionId: string): Promise<GetFlashcardResponse[]> {
        try {
            const token = this.store.state.accessToken;

            const response = await this.httpService.get(`collection/${collectionId}/flashcards`, token);

            return response;
        }
        catch(err: any) {
            console.log(err);
            throw err as string;
        }
    }

    public async createFlashcard(createFlashcardRequest: CreateFlashcardRequest) {
        try {
            const token = this.store.state.accessToken;

            const response = await this.httpService.post('', createFlashcardRequest, token);

            return response;
        }
        catch(err: any) {
            console.log(err);
            throw err as string;
        }
    }

}

export default FlashcardService;