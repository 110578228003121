
import { defineComponent } from 'vue';
import Form from '@/components/native/Form.vue';
import FormGroup from '@/components/native/FormGroup.vue';
import Button from '@/components/native/Button.vue';
import FormSubmitEvent from '@/types/native/events/FormSubmitEvent';

export default defineComponent({
    components: {
        Form,
        FormGroup,
        Button
    },
    setup() {
        function handleSubmit(e: FormSubmitEvent) {
            console.log(e);
            e.formData.forEach((value, key) => console.log(`${key} => ${value}`));
        }

        return { handleSubmit };
    }
})
